















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ObjectsContent from '@/components/content/ObjectsContent.vue';
import GenericContent from '@/components/content/GenericContent.vue';
import ObjectsFilter from '@/components/content/ObjectsFilter.vue';

@Component({
  components: {
    ObjectsFilter,
    ObjectsContent,
    GenericContent,
  },
})
export default class Market extends Vue {
  public filterData = null;

  public noObjects = null;

  public initialLoad = false;

  @Watch('noObjects')
  public objectsSelected() {
    this.initialLoad = true;
  }
}
